.blog-view{
    width: 70%;
    margin: 0 auto;
}
.blog-title{
    text-align: center;
}
.author{
    text-align: center;
}
.blog-body{
    margin: 0 auto;
    margin-top: 40px;
    width: 100%;
}
.blog-image{
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    aspect-ratio: 1/1;
    max-width: 100%;
}
.blog-text{
    margin-top: 30px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    max-width: 100%;
}