.reply-svg .delete-svg{
    cursor:pointer;
    height:20px;
    width:20px;
}

.message{
    margin-bottom: 20px;
    width: 100%;
    word-wrap: break-word;
}

.msg{
    padding: 10px;
    border-radius: 5px;  
    margin-bottom: 10px;  
}

.sent-username{
    font-weight: bold;
    font-size: large;
}

.reply-msg{
    padding: 5px;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 97%;
    position: relative;
    left:2.7%;
    z-index: -1;
}

.msg:hover{
    background-color: rgba(0,0,0,0.5);   
}


